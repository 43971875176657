export enum EduLevel {
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
  P6 = 'P6',
  S1 = 'S1',
  S2 = 'S2',
  S3 = 'S3',
  S4 = 'S4',
  J1 = 'J1',
  J2 = 'J2',
}

export enum QuestionType {
  MCQ = 'MCQ',
  OEQ = 'OEQ',
}

export enum ExamType {
  CA1 = 'CA1',
  CA2 = 'CA2',
  SA1 = 'SA1',
  SA2 = 'SA2',
  PSLE = 'PSLE',
  WA = 'WA',
  SA = 'SA',
  O_LEVEL = 'O_LEVEL',
  COMMON_TEST = 'COMMON_TEST',
  BLOCK_TEST = 'BLOCK_TEST',
  PROMO = 'PROMO',
  PRELIM = 'PRELIM',
  A_LEVEL = 'A_LEVEL',
  OTHER = 'OTHER',
}

export enum SchoolType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  JC = 'JC',
}

export enum Difficulty {
  EASY = 'EASY',
  MEDIUM = 'MEDIUM',
  HARD = 'HARD',
}

export enum ProgressStatus {
  IN_PROGRESS = 'In Progress',
  SUBMITTED = 'submitted',
  MARKED = 'marked',
}

export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
}
