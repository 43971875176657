import { Difficulty, EduLevel, ExamType, SchoolType } from '../types/prisma-types';

export const debugStyle = { border: '1px solid red' };

export const educationLevelOptions = [
  { value: 'P1', label: 'Primary 1' },
  { value: 'P2', label: 'Primary 2' },
  { value: 'P3', label: 'Primary 3' },
  { value: 'P4', label: 'Primary 4' },
  { value: 'P5', label: 'Primary 5' },
  { value: 'P6', label: 'Primary 6' },
  { value: 'S1', label: 'Secondary 1' },
  { value: 'S2', label: 'Secondary 2' },
  { value: 'S3', label: 'Secondary 3' },
  { value: 'S4', label: 'Secondary 4' },
  { value: 'J1', label: 'JC 1' },
  { value: 'J2', label: 'JC 2' },
];

export const difficultyOptions: { value: Difficulty; label: Difficulty }[] = [
  { value: Difficulty.EASY, label: Difficulty.EASY },
  { value: Difficulty.MEDIUM, label: Difficulty.MEDIUM },
  { value: Difficulty.HARD, label: Difficulty.HARD },
];

export const examTypeOrder: Record<ExamType, number> = {
  [ExamType.CA1]: 1,
  [ExamType.CA2]: 2,
  [ExamType.SA1]: 3,
  [ExamType.SA2]: 4,
  [ExamType.PSLE]: 5,
  [ExamType.WA]: 6,
  [ExamType.SA]: 7,
  [ExamType.O_LEVEL]: 8,
  [ExamType.COMMON_TEST]: 9,
  [ExamType.BLOCK_TEST]: 10,
  [ExamType.PROMO]: 11,
  [ExamType.PRELIM]: 12,
  [ExamType.A_LEVEL]: 13,
  [ExamType.OTHER]: 14,
};

export const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/png'];

export const MIN_QUESTION_PART_NUM = 1;
export const MAX_QUESTION_PART_NUM = 50;

export const questionIndex = [
  { value: 'root', label: '-' },
  { value: 'a', label: 'a' },
  { value: 'b', label: 'b' },
  { value: 'c', label: 'c' },
  { value: 'd', label: 'd' },
  { value: 'e', label: 'e' },
  { value: 'f', label: 'f' },
  { value: 'g', label: 'g' },
  { value: 'h', label: 'h' },
  { value: 'i', label: 'i' },
  { value: 'j', label: 'j' },
  { value: 'k', label: 'k' },
  { value: 'l', label: 'l' },
  { value: 'm', label: 'm' },
  { value: 'n', label: 'n' },
  { value: 'o', label: 'o' },
  { value: 'p', label: 'p' },
  { value: 'q', label: 'q' },
  { value: 'r', label: 'r' },
  { value: 's', label: 's' },
  { value: 't', label: 't' },
  { value: 'u', label: 'u' },
  { value: 'v', label: 'v' },
  { value: 'w', label: 'w' },
  { value: 'x', label: 'x' },
  { value: 'y', label: 'y' },
  { value: 'z', label: 'z' },
];

export const questionSubIndex = [
  { value: 'root', label: '-' },
  { value: 'i', label: 'i' },
  { value: 'ii', label: 'ii' },
  { value: 'iii', label: 'iii' },
  { value: 'iv', label: 'iv' },
  { value: 'v', label: 'v' },
  { value: 'vi', label: 'vi' },
  { value: 'vii', label: 'vii' },
  { value: 'viii', label: 'viii' },
  { value: 'ix', label: 'ix' },
  { value: 'x', label: 'x' },
];

export const schoolTypeMapToEduLevel: { [key: string]: string[] } = {
  PRIMARY: ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'],
  SECONDARY: ['S1', 'S2', 'S3', 'S4'],
  JC: ['J1', 'J2'],
};

export const eduLevelMapToSchoolType: { [key: string]: SchoolType } = {
  P1: SchoolType.PRIMARY,
  P2: SchoolType.PRIMARY,
  P3: SchoolType.PRIMARY,
  P4: SchoolType.PRIMARY,
  P5: SchoolType.PRIMARY,
  P6: SchoolType.PRIMARY,
  S1: SchoolType.SECONDARY,
  S2: SchoolType.SECONDARY,
  S3: SchoolType.SECONDARY,
  S4: SchoolType.SECONDARY,
  J1: SchoolType.JC,
  J2: SchoolType.JC,
};

export const questionTypeOptions = [
  { value: 'MCQ', label: 'MCQ' },
  { value: 'OEQ', label: 'OEQ' },
];

export const examTypeEducationLevelMapping: {
  examType: string;
  enumValue: ExamType;
  educationLevel: EduLevel[];
}[] = [
  {
    examType: 'CA1',
    enumValue: ExamType.CA1,
    educationLevel: [EduLevel.P1, EduLevel.P2, EduLevel.P3, EduLevel.P4, EduLevel.P5, EduLevel.P6],
  },
  {
    examType: 'CA2',
    enumValue: ExamType.CA2,
    educationLevel: [EduLevel.P1, EduLevel.P2, EduLevel.P3, EduLevel.P4, EduLevel.P5, EduLevel.P6],
  },
  {
    examType: 'SA1',
    enumValue: ExamType.SA1,
    educationLevel: [EduLevel.P1, EduLevel.P2, EduLevel.P3, EduLevel.P4, EduLevel.P5, EduLevel.P6],
  },
  {
    examType: 'SA2',
    enumValue: ExamType.SA2,
    educationLevel: [EduLevel.P1, EduLevel.P2, EduLevel.P3, EduLevel.P4, EduLevel.P5, EduLevel.P6],
  },
  {
    examType: 'PSLE',
    enumValue: ExamType.PSLE,
    educationLevel: [EduLevel.P6],
  },
  {
    examType: 'WA',
    enumValue: ExamType.WA,
    educationLevel: [EduLevel.S1, EduLevel.S2, EduLevel.S3, EduLevel.S4],
  },
  {
    examType: 'SA',
    enumValue: ExamType.SA,
    educationLevel: [EduLevel.S1, EduLevel.S2, EduLevel.S3, EduLevel.S4],
  },
  {
    examType: 'O Level',
    enumValue: ExamType.O_LEVEL,
    educationLevel: [EduLevel.S4],
  },
  {
    examType: 'Common test',
    enumValue: ExamType.COMMON_TEST,
    educationLevel: [EduLevel.S1, EduLevel.S2, EduLevel.S3, EduLevel.S4],
  },
  {
    examType: 'Block test',
    enumValue: ExamType.BLOCK_TEST,
    educationLevel: [EduLevel.S1, EduLevel.S2, EduLevel.S3, EduLevel.S4],
  },
  {
    examType: 'Prelim',
    enumValue: ExamType.PRELIM,
    educationLevel: [EduLevel.J1, EduLevel.J2],
  },
  {
    examType: 'Promo',
    enumValue: ExamType.PROMO,
    educationLevel: [EduLevel.J1, EduLevel.J2],
  },
  {
    examType: 'A-level',
    enumValue: ExamType.A_LEVEL,
    educationLevel: [EduLevel.J2],
  },
  {
    examType: 'Other',
    enumValue: ExamType.OTHER,
    educationLevel: Object.values(EduLevel) as EduLevel[],
  },
];

// Breakpoint constants
export const SM_BREAKPOINT = 640;
export const MD_BREAKPOINT = 768;
export const LG_BREAKPOINT = 1024;
export const XL_BREAKPOINT = 1280;
export const XXL_BREAKPOINT = 1536;
